import {combineReducers} from 'redux';

import auth from './auth';
import api from './api';
import state from './state';

const rootReducer = combineReducers({
  auth,
  api,
  state
});

export default rootReducer;
