module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Montserrat"],"urls":["/fonts/montserrat.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kodai","short_name":"Kodai","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/images/logo_black.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"26b30168be4ed75ae2c6cb3ba15ff778"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"mediaTypes":["text/markdown","text/x-markdown"],"remarkPlugins":[null],"gatsbyRemarkPlugins":["gatsby-transformer-mdx","gatsby-remark-relative-images",{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static","ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff"]}},{"resolve":"gatsby-remark-images","options":{"maxWidth":750,"linkImagesToOriginal":false,"plugins":[{"resolve":"gatsby-remark-image-attributes","options":{"dataAttributes":true}}]}}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"root":"/Users/kissveronika/kodaiweb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
