import React from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { createStore as reduxCreateStore } from 'redux';
import { applyMiddleware } from 'redux';
import rootReducer from './src/redux/reducers';
import thunk from 'redux-thunk';
import { window, document, exists } from 'browser-monads';


// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const middlewares = [thunk];
  const createStoreWithMiddleware = applyMiddleware(...middlewares)(
    reduxCreateStore,
  );

  const store = createStoreWithMiddleware(rootReducer);

  return (
    <CookiesProvider>
      <Provider store={store}>{element}</Provider>
    </CookiesProvider>
  );
};
