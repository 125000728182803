export const initialState = {
  songUrl: '',
  message: ''
};

const state = (state = initialState, action) => {
  switch (action.type) {
    // state
    //*************************************  USERS  ************************************************** */
    case 'SET_URL':
      return {
        ...state,
        songUrl: action.data,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        message: action.data,
      };
    default:
      return state;
  }
};

export default state;
