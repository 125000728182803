export const initialState = {
  code: '',
  codeexpired: false,
  access_token: '',
  refresh_token: '',
  user_id: '',
  error: '',
  expires_in: 0,
  date: new Date(),
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    // state
    case 'LOGOUT':
      return initialState;
    case 'LOGIN_SUCCESS':
      return action.data;
    case 'GET_CODE':
      return {
        ...state,
        code: action.data,
      };
    case 'GET_CODE_EXPIRED':
      return {
        ...state,
        code: '',
        codeexpired: true,
      };
    case 'GET_TOKEN_FROM_CODE':
      return {
        ...state,
        code: '',
        codeexpired: false,
        access_token: action.data.access_token,
        refresh_token: action.data.refresh_token,
        user_id: action.data.user_id,
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        error: action.data,
      };
    case 'REGISTRATION_ERROR':
      return {
        ...state,
        error: '',
      };
    case 'REGISTRATION':
      return {
        ...state,
        error: '',
      };
    case 'REFRESH_TOKEN_SUCCESS':
      return action.data;
    case 'TOKEN_FROM_ASNYCSTORAGE_SUCCESS':
      return {
        ...state,
        access_token: action.data.access_token,
        refresh_token: action.data.refresh_token,
        user_id: action.data.user_id,
        expires_in: action.data.expires_in,
        error: '',
      };
    case 'REFRESH_TOKEN_FAILED':
      return {
        access_token: '',
        refresh_token: '',
        user_id: '',
        error: '',
        expires_in: 0,
        date: new Date(),
        error: action.data,
      };
    default:
      return state;
  }
};

export default auth;
