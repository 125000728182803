var _ = require('lodash');

export const initialState = {
  user: {},
  users:[],
  error: '',
  logError: '',
  workspaces: [],
  projects: [],
  songs: [],
  notificationId: ''
};

const api = (state = initialState, action) => {
  switch (action.type) {
    // state
    //*************************************  USERS  ************************************************** */
    case 'REGISTRATION':
      return {
        ...state,
        user: action.data,
        regError: '',
      };
    case 'SENT_NOTIFICATION_ID':
      return {
        ...state,
        notificationId: action.data,
      };
    case 'SENT_NOTIFICATION_ID_ERROR':
      return {
        ...state,
        notificationId: action.data,
      };

    case 'REGISTRATION_ERROR':
      return {
        ...state,
        user: {},
        regError: action.data,
      };
      case 'GET_ME': {
        return {
          ...state,
          workspaces: action.data.workspaces.map((w) => _.omit(w, ['projects'])),
          projects: action.data.workspaces.flatMap((w) =>
            w.projects.flatMap((p) => _.omit(p, ['songs'])).reverse(),
          ),
          songs: action.data.workspaces.flatMap((w) =>
            w.projects.flatMap((p) => p.songs.flatMap((s) => s)).reverse(),
          ),
        };
      }
      case 'GET_ME_USERDATA': {
        return {
          ...state,
          user: _.omit(action.data, ['workspaces']),
        };
      }
    case 'GET_ME_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.data,
      };
    case 'GET_USERS':
      return {
        ...state,
        users: action.data,
      };
    case 'UPDATE_USER_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'DELETE_USER':
      return {
        ...state,
        user: action.data,
      };
    case 'DELETE_USER_ERROR':
      return {
        ...state,
        error: action.data,
      };
    //*************************************  WORKSPACES  ************************************************** */
    case 'GET_WORKSPACE':
      return {
        ...state,
        workspace: action.data,
      };
    case 'GET_WORKSPACE_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'CREATE_WORKSPACE':
      return {
        ...state,
        workspaces: action.workspaces,
        projects: action.projects,
      };
    case 'CREATE_WORKSPACE_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'UPDATE_WORKSPACE_TITLE':
      return state;
    case 'UPDATE_WORKSPACE_TITLE_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'UPDATE_WORKSPACE_PROJECTS':
      return state;
    case 'UPDATE_WORKSPACE_PROJECTS_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'DELETE_WORKSPACE':
      return state;
    case 'DELETE_WORKSPACE_ERROR':
      return {
        ...state,
        error: action.data,
      };
    //*************************************  PROJECTS  ************************************************** */
    case 'CREATE_PROJECT':
      return state;
    case 'CREATE_PROJECT_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'UPDATE_PROJECT_TITLE':
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.id === action.data.id) {
            return action.data;
          } else {
            return project;
          }
        }),
      };
    case 'UPDATE_PROJECT_TITLE_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'UPDATE_PROJECT_SONGS':
      return state;
    case 'UPDATE_PROJECT_SONGS_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'DELETE_PROJECT':
      return state;
    case 'DELETE_PROJECT_ERROR':
      return {
        ...state,
        error: action.data,
      };
    //*************************************  SONGS  ************************************************** */
    case 'CREATE_SONG':
      return state;
    case 'CREATE_SONG_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'CREATE_SONG_WITH_PROJECT':
      return state;
    case 'CREATE_SONG_WITH_PROJECT_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'UPDATE_SONG':
      return state;
    case 'UPDATE_SONG':
      return {
        ...state,
        error: action.data,
      };
    case 'DELETE_SONG':
      return state;
    case 'DELETE_SONG_ERROR':
      return {
        ...state,
        error: action.data,
      };
    default:
      return state;
  }
};

export default api;
